#root {
  background: #f6f5f8;
}

html {
  background: #f6f5f8;
}

#root {
  background: #f6f5f8;
}

html {
  background: #f6f5f8;
}

.header {
  /* position: fixed;
top: 0; */
  width: 100%;
  height: 100vh;
  background: #f6f5f8;
}

.active {
  background: red;
}

.toggleFilterContainer {
  display: none;
}

.navbarContainerNew {
  background-color: #212121;
  height: 54px;
  display: flex;
  align-items: center;
}

.font12x400 {
  font-size: 12px;
  font-weight: 400;
}

.font12x600 {
  font-size: 12px;
  font-weight: 600;
}

.menuDivNew {
  height: 100%;
}

.welcomeContainer {
  position: relative;
}

.welcomeContainer:hover .hoverMenu {
  display: block;
}

.hoverMenu {
  width: 166px;
  position: absolute;
  top: 20px;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 10px 20px 6px rgba(112, 144, 176, 0.2);
  border-radius: 8px;
  padding: 15px;
  display: none;
  z-index: 2 !important;
}

.hoverMenu ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

.hoverMenu ul li {
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.hoverMenu ul li:hover {
  background-color: #ffd3d3;
}

.hoverMenu ul li:hover a {
  color: #ff0000 !important;
}

.hoverMenu ul li a {
  font-size: 12px;
  text-decoration: none;
  color: #000000;
}

.filterPaggContainer {
  margin-top: 10px;
}

.filterPaggDiv {
  background: #ffffff;
  /* box-shadow: 0px 10px 20px rgba(112, 144, 176, 0.2); */
  border-radius: 8px;
  padding: 15px 16px 15px 26px;
  margin: 0 10px;
}

.filterContainer {
  width: 100%;
}

.city {
  position: relative;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  background-color: #ffdddd;
  padding: 5px 15px;
  z-index: 1 !important;
}

.city:active {
  outline: 1px solid #ff0000;
}

.cityOption {
  position: absolute !important;
  top: 35px !important;
  left: 0;
  background-color: #fff;
  font-size: 12px;
  padding: 15px;
  box-shadow: 0px 10px 20px 6px rgba(112, 144, 176, 0.2);
  border-radius: 8px;
}

.cityOption p {
  padding: 5px 30px 5px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.cityOption p:hover {
  background-color: #ffd3d3;
}

.cityOption p:hover {
  color: #ff0000;
}

.date {
  position: relative;
  padding: 5px 15px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  background-color: #dde0ff;
  outline: 1px solid #3b4cff;
  z-index: 1 !important;
}

.dateOption {
  position: absolute !important;
  top: 35px !important;
  left: 0;
  background-color: #fff;
  font-size: 12px;
  padding: 15px;
  box-shadow: 0px 10px 20px 6px rgba(112, 144, 176, 0.2);
  border-radius: 8px;
}

.dateOption p {
  padding: 5px 30px 5px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.dateOption p:hover {
  background-color: #dde0ff;
}

.dateOption p:hover {
  color: #3b4cff;
}

.table-margin {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.result {
  position: relative;
  padding: 5px 15px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  background-color: #ffddf3;
  outline: 1px solid #ff39b9;
  z-index: 1 !important;
}

.end {
  width: 100%;
  padding: 1px 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  background: #99ccff;
}

.stylist_confirm {
  width: 100%;
  padding: 1px 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  background: #db973f;
}

.confirmed {
  width: 100%;
  padding: 1px 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  background: #97c1a9;
}

.curser {
  cursor: pointer;
}

.start {
  width: 100%;
  padding: 1px 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  background: #90cbaa;
}

.inTransit {
  width: 100%;
  padding: 1px 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  background: #cbaacb;
}

.pending {
  width: 100%;
  padding: 1px 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  background: #fe7d8f;
}

.end,
.confirmed,
.start,
.inTransit,
.pending>a {
  color: #fff !important;
}

.blockBtn {
  width: 110px;
  height: 25px;
  background: #ff9899;
  border-radius: 8px;
  outline: none;
  border: none;
  color: #fff;
  font-size: 12px;
}

.ReleaseBtn {
  width: 110px;
  height: 25px;
  background: #a2e1db;
  border-radius: 8px;
  outline: none;
  border: none;
  color: #fff;
  font-size: 12px;
}

.halfDayBtn {
  width: 110px;
  height: 25px;
  background: #d9c382;
  border-radius: 8px;
  outline: none;
  border: none;
  color: #fff;
  font-size: 12px;
}

.accordion-header {
  width: 200px;
}

.accordion-item {
  width: 200px;
}

.accordion-button {
  height: 15px;
  padding: 15px 15px 15px 5px;
  font-size: 12px;
  margin-bottom: 5px;
  background-color: #dfdfdf !important;
  border-radius: 8px !important;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #dfdfdf;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  border-radius: 8px !important;
}

.accordion-button:focus {
  z-index: 1;
  /* border-color: none; */
  border: none;
  outline: 0;
  box-shadow: 0 0 0 .25remrgba(13, 110, 253, .25);
  border-radius: 8px !important;
}

.accordion-item {
  border: none;
  border-radius: 8px !important;
  margin-bottom: 10px;
}

.accordion-body {
  background-color: #dfdfdf;
  border-radius: 8px !important;
  padding: 0.25rem 1.25rem !important;
}

.resultOption {
  position: absolute !important;
  top: 35px !important;
  left: 0;
  background-color: #fff;
  font-size: 12px;
  padding: 15px;
  box-shadow: 0px 10px 20px 6px rgba(112, 144, 176, 0.2);
  border-radius: 8px;
}

.resultOption p {
  padding: 5px 30px 5px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.resultOption p:hover {
  background-color: #ffddf3;
}

.resultOption p:hover {
  color: #ff39b9;
}

.paginationBtn {
  padding: 5px 10px;
  border-radius: 8px;
}

.paginationBtn:hover {
  background-color: #ffdddd;
}

.pagination-active {
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #ffdddd;
}

.table-container-new {
  margin: 10px 10px 0 10px;
  background: #ffffff;

  border-radius: 8px;
  height: 100%;
  overflow: scroll;
}

.table-container-new::-webkit-scrollbar {
  display: none;
}

thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-bottom: 2px solid #e7e7e7 !important;
  text-transform: uppercase;
  color: #ff7272;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  z-index: 1;
}

tbody tr {
  border-bottom: 2px solid #e7e7e7 !important;
}

th {
  padding: 20px !important;
}

td {
  padding: 20px !important;
  font-size: 12px;
  font-weight: 400;
}

td a {
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  color: #fff;
}

td ul {
  padding-left: 1rem !important;
}

td ul li {
  margin-bottom: 10px !important;
}

td ul svg {
  font-size: 18px;
}


input[type="checkbox"] {
  appearance: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 2px solid #ff7272;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="checkbox"]:after {
  content: "\2713";
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  display: none;
}

input[type="checkbox"]:checked {
  background-color: #ff7272;
}

input[type="checkbox"]:checked::after {
  display: block;
}

.blockAllS {
  padding: 8px 10px !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  background-color: #a90006;
}

.blockAllS:hover {
  color: #fff !important;
}

.releaseBtn {
  padding: 8px 20px !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  background-color: #7ca0c7;
}

.slotBtnG {
  width: 100%;
  padding: 8px 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  /* background-color: #0fef69; */
}

.slotBtnG:hover {
  color: #fff !important;
}

.slotBtnY {
  width: 100%;
  padding: 8px 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  background-color: #efb00f;
}

.slotBtnY:hover {
  color: #fff !important;
}

.slotBtnP {
  width: 100%;
  padding: 8px 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  background-color: #ce2a2e;
}

.slotBtnP:hover {
  color: #fff !important;
}

.slotBtnO {
  width: 100%;
  padding: 8px 0 !important;
  font-size: 12px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 8px;
  text-align: center;
  background-color: #f39a10;
}

.slotBtnO:hover {
  color: #fff !important;
}

.liveStatus {
  margin-top: 5px;
  list-style: square !important;
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"] {
  width: 200px;
  font-size: 12px;
  font-weight: 400;
  color: #9a9da2;
  outline: none !important;
  border: none !important;
}

.searchContainer {
  border: 1px solid #ff0000;
  border-radius: 8px;
  padding: 3px 10px;
}

/* for left */
.react-switch-checkbox {
  height: 0;
  width: 0;
  display: none !important;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 46px;
  height: 24px;
  background: #0fef69;
  border-radius: 25px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

/* for right */
.react-switch-checkbox-r {
  height: 0;
  width: 0;
  display: none !important;
}

.react-switch-label-r {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 46px;
  height: 24px;
  background: #0fef69;
  border-radius: 25px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label-r .react-switch-button-r {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 20px;
  height: 20px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox-r:checked+.react-switch-label-r .react-switch-button-r {
  right: calc(100% - 2px);
  transform: translateX(100%);
}

.blocked-area {
  background-color: #F2F2F2 !important;
  text-align: center;
}

.react_switch {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #f6f5f8;
  }

  .active {
    background: red;
  }

  .navbarContainerNew {
    position: fixed;
    top: 0;
    z-index: 1;
  }

  .adminNameContainer {
    display: none;
  }

  .filterPaggContainer {
    display: none;
  }

  .table-container-new {
    margin: 60px 10px 0 10px;
    height: 100%;
  }

  .toggleFilterContainer {
    display: none;
  }

  .navbarContainerNew {
    background-color: #212121;
    height: 54px;
    display: flex;
    align-items: center;
  }

  .font12x400 {
    font-size: 12px;
    font-weight: 400;
  }

  .font12x600 {
    font-size: 12px;
    font-weight: 600;
  }

  .menuDivNew {
    height: 100%;
  }

  .welcomeContainer {
    position: relative;
  }

  .welcomeContainer:hover .hoverMenu {
    display: block;
  }

  .hoverMenu {
    width: 166px;
    position: absolute;
    top: 20px;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 10px 20px 6px rgba(112, 144, 176, 0.2);
    border-radius: 8px;
    padding: 15px;
    display: none;
    z-index: 2 !important;
  }

  .hoverMenu ul {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
  }

  .hoverMenu ul li {
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
  }

  .hoverMenu ul li:hover {
    background-color: #ffd3d3;
  }

  .hoverMenu ul li:hover a {
    color: #ff0000 !important;
  }

  .hoverMenu ul li a {
    font-size: 12px;
    text-decoration: none;
    color: #000000;
  }

  .filterPaggContainer {
    margin-top: 10px;
  }

  .filterPaggDiv {
    background: #ffffff;
    border-radius: 8px;
    padding: 15px 16px 15px 26px;
    margin: 0 10px;
  }

  .filterContainer {
    width: 100%;
  }

  .city {
    position: relative;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    background-color: #ffdddd;
    padding: 5px 15px;
    z-index: 1 !important;
  }

  .city:active {
    outline: 1px solid #ff0000;
  }

  .cityOption {
    position: absolute !important;
    top: 35px !important;
    left: 0;
    background-color: #fff;
    font-size: 12px;
    padding: 15px;
    box-shadow: 0px 10px 20px 6px rgba(112, 144, 176, 0.2);
    border-radius: 8px;
  }

  .cityOption p {
    padding: 5px 30px 5px 10px;
    border-radius: 8px;
    cursor: pointer;
  }

  .cityOption p:hover {
    background-color: #ffd3d3;
  }

  .cityOption p:hover {
    color: #ff0000;
  }

  .date {
    position: relative;
    padding: 5px 15px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    background-color: #dde0ff;
    outline: 1px solid #3b4cff;
    z-index: 1 !important;
  }

  .dateOption {
    position: absolute !important;
    top: 35px !important;
    left: 0;
    background-color: #fff;
    font-size: 12px;
    padding: 15px;
    box-shadow: 0px 10px 20px 6px rgba(112, 144, 176, 0.2);
    border-radius: 8px;
  }

  .dateOption p {
    padding: 5px 30px 5px 10px;
    border-radius: 8px;
    cursor: pointer;
  }

  .dateOption p:hover {
    background-color: #dde0ff;
  }

  .dateOption p:hover {
    color: #3b4cff;
  }

  .result {
    position: relative;
    padding: 5px 15px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    background-color: #ffddf3;
    outline: 1px solid #ff39b9;
    z-index: 1 !important;
  }

  .resultOption {
    position: absolute !important;
    top: 35px !important;
    left: 0;
    background-color: #fff;
    font-size: 12px;
    padding: 15px;
    box-shadow: 0px 10px 20px 6px rgba(112, 144, 176, 0.2);
    border-radius: 8px;
  }

  .resultOption p {
    padding: 5px 30px 5px 10px;
    border-radius: 8px;
    cursor: pointer;
  }

  .resultOption p:hover {
    background-color: #ffddf3;
  }

  .resultOption p:hover {
    color: #ff39b9;
  }

  .paginationBtn {
    padding: 5px 10px;
    border-radius: 8px;
  }

  .paginationBtn:hover {
    background-color: #ffdddd;
  }

  .table-container-new {
    margin: 10px 10px 0 10px;
    background: #ffffff;

    border-radius: 8px;
    height: 100%;
    overflow: scroll;
  }

  .table-container-new::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .table-container-new {
      height: 100%;
    }

    thead {
      position: sticky;
      top: 0;
      background-color: #fff;
      border-bottom: 2px solid #e7e7e7 !important;
      text-transform: uppercase;
      color: #ff7272;
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      z-index: 0;
    }

    tbody tr {
      border-bottom: 2px solid #e7e7e7 !important;
    }

    th {
      padding: 20px !important;
    }

    td {
      padding: 20px !important;
      font-size: 12px;
      font-weight: 400;
    }

    td a {
      text-decoration: none;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: #0047ff !important;
    }

    td ul {
      padding-left: 1rem !important;
    }

    td ul li {
      margin-bottom: 10px !important;
    }

    td ul svg {
      font-size: 18px;
    }

    input[type="checkbox"] {
      appearance: none !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 4px;
      width: 16px;
      height: 16px;
      background-color: #fff;
      border: 2px solid #ff7272;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input[type="checkbox"]:after {
      content: "\2713";
      color: #fff;
      font-size: 10px;
      font-weight: bold;
      display: none;
    }

    input[type="checkbox"]:checked {
      background-color: #ff7272;
    }

    input[type="checkbox"]:checked::after {
      display: block;
    }

    .blockAllS {
      padding: 8px 10px !important;
      font-size: 12px;
      font-weight: 400;
      color: #fff !important;
      border-radius: 8px;
      text-align: center;
      background-color: #a90006;
    }

    .blockAllS:hover {
      color: #fff !important;
    }

    .releaseBtn {
      padding: 8px 20px !important;
      font-size: 12px;
      font-weight: 400;
      color: #fff !important;
      border-radius: 8px;
      text-align: center;
      background-color: #7ca0c7;
    }

    .slotBtnG {
      width: 100%;
      padding: 8px 0 !important;
      font-size: 12px;
      font-weight: 400;
      color: #fff !important;
      border-radius: 8px;
      text-align: center;

    }

    .slotBtnY {
      width: 100%;
      padding: 8px 0 !important;
      font-size: 12px;
      font-weight: 400;
      color: #fff !important;
      border-radius: 8px;
      text-align: center;

    }


    .slotBtnP {
      width: 100%;
      padding: 8px 0 !important;
      font-size: 12px;
      font-weight: 400;
      color: #fff !important;
      border-radius: 8px;
      text-align: center;
      background-color: #ce2a2e;
    }

    .slotBtnP:hover {
      color: #fff !important;
    }

    .slotBtnO {
      width: 100%;
      padding: 8px 0 !important;
      font-size: 12px;
      font-weight: 400;
      color: #fff !important;
      border-radius: 8px;
      text-align: center;
      background-color: #f39a10;
    }

    .slotBtnO:hover {
      color: #fff !important;
    }

    .liveStatus {
      margin-top: 5px;
      list-style: square !important;
    }

    input[type="search"]::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    input[type="search"]::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      display: none;
    }

    input[type="search"] {
      width: 200px;
      font-size: 12px;
      font-weight: 400;
      color: #9a9da2;
      outline: none !important;
      border: none !important;
    }

    .searchContainer {
      border: 1px solid #ff0000;
      border-radius: 8px;
      padding: 3px 10px;
    }

    /* for left */
    .react-switch-checkbox {
      height: 0;
      width: 0;
      display: none !important;
    }

    .react-switch-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      width: 46px;
      height: 24px;
      background: #0fef69;
      border-radius: 25px;
      position: relative;
      transition: background-color 0.2s;
    }

    .react-switch-label .react-switch-button {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      border-radius: 45px;
      transition: 0.2s;
      background: #fff;
      box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }

    .react-switch-checkbox:checked+.react-switch-label .react-switch-button {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }

    /* for right */
    .react-switch-checkbox-r {
      height: 0;
      width: 0;
      display: none !important;
    }

    .react-switch-label-r {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      width: 46px;
      height: 24px;
      background: #0fef69;
      border-radius: 25px;
      position: relative;
      transition: background-color 0.2s;
    }

    .react-switch-label-r .react-switch-button-r {
      content: "";
      position: absolute;
      top: 2px;
      right: 2px;
      width: 20px;
      height: 20px;
      border-radius: 45px;
      transition: 0.2s;
      background: #fff;
      box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }

    .react-switch-checkbox-r:checked+.react-switch-label-r .react-switch-button-r {
      right: calc(100% - 2px);
      transform: translateX(100%);
    }
  }
}

@media screen and (max-width: 1100px) {
  .header {
    position: fixed;
    top: 0;
    overflow-x: scroll;
  }

  .navbarContainerNew {
    position: fixed;
    top: 0;
    z-index: -10;
  }

  .adminNameContainer {
    display: none;
  }

  .filterPaggContainer {
    display: none;
  }

  .table-container-new {
    margin: 60px 10px 0 10px;
    height: 100%;
  }

  .toggleFilterContainer {

    display: block;
    cursor: pointer;
  }

  .toggleFilterContainer svg {
    color: #fff;
    font-size: 25px;
  }

  .react_switch {
    display: none;
  }

  .filterContainerMobile {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 100 !important;
    /* width: 50%; */
    height: 100%;
  }

  .filterMobileWrapper {
    padding: 20px 30px;
  }

  .filterIconContainer svg {
    cursor: pointer;
    font-size: 25px;
    color: #222222;
  }

  .city {
    position: static;
  }

  .cityOption {
    position: static !important;
  }

  .date {
    position: static;
  }

  .dateOption {
    position: static !important;
  }

  .owner {
    position: static;
  }

  .ownerOption {
    position: static !important;
  }

  .result {
    position: static;
  }

  .resultOption {
    position: static !important;
  }

  .table-container-new {
    min-width: max-content;
  }
}