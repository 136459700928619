
.login-body {
    max-width: 500px;
    height: 100vh;
    padding-top: 10%;
    margin: 0 auto;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
} */

 .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  } 

  @media (max-width: 500px) {
    .login-body {
      width: 100%;
      max-width: 300px;
      height: 100vh;
      padding-top: 10%;
      margin: 0 auto;
  }
  }